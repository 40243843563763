import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { LanguageProvider } from "./assets/translations/Traslations.jsx";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
//No quitar el css de bootstrap ya que se necesita para el funcionamiento de la página web
import 'bootstrap/dist/css/bootstrap.css';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>
);
