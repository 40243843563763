import React, { useState, useEffect } from "react";
import "./Navbar.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../assets/translations/Traslations";
import data from "../../assets/scripts/scripts";

const MyNavbar = () => {

  const { language } = useLanguage();

  const { NavbarData } = data;

  const traductions = (data) => {
    return data[language];
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const togglee = () => setDropdownOpen(!dropdownOpen);
  const showDropdown = () => setDropdownOpen(true);
  const hideDropdown = () => setDropdownOpen(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();


  const handleChange = () => {
  const RoutesName = {
    es: {
      home: "/",
      about: "/nosotros",
      contact: "/contacto",
      textileDivision: "/division-textil",
      fullPackage: "/paquete-completo",
      terms: "/t-y-c",
      notFound: "*",

    },
    en: {
      home: "/",
      about: "/about-us",
      contact: "/contact",
      textileDivision: "/textile-division",
      fullPackage: "/full-package",
      terms: "/t-y-c",
      notFound: "*",
    },
  };

  if (language === "es") {
    localStorage.setItem("language", "en");
    window.location.href = RoutesName.en.home;
  }
  if (language === "en") {

    localStorage.setItem("language", "es");
    window.location.href = RoutesName.es.home;
  }

  };



  useEffect(() => {
    const handleScroll = () => {
      const threshold = 50; // Umbral para determinar si la página está desplazada

      // Si la ventana ha sido desplazada más de 100 píxeles, cambia el estado
      if (window.scrollY > threshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Agrega un evento para detectar el desplazamiento
    window.addEventListener("scroll", handleScroll);

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Ejecuta el efecto solo una vez al montar el componente

  // Aplica la clase adicional si el usuario ha desplazado la página
  const navbarClassName = isScrolled ? "NavbarStyle scrolled" : "NavbarStyle";

 

  return (
    <Navbar expand="md" className={navbarClassName}>
      <NavbarBrand onClick={() => {navigate(traductions(NavbarData).navigate)}}>
          <h1 className="title-disex">{traductions(NavbarData).disex}</h1>
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse
        isOpen={isOpen}
        navbar //navbar es para que se vea como un link
      >
        <Nav className="mx-auto">
          <NavItem>
            <NavLink onClick={() => {navigate(traductions(NavbarData).navigate)}} className="nav-link">
            {traductions(NavbarData).home}
            </NavLink>
          </NavItem>

          <UncontrolledDropdown
            inNavbar
            onMouseEnter={showDropdown}
            onMouseLeave={hideDropdown}
            isOpen={dropdownOpen}
            toggle={togglee}
          >
            <DropdownToggle
              nav //nav es para que se vea como un link
              caret //caret es la flechita que se muestra al lado del texto
              className="nav-link"
            >
              {traductions(NavbarData).services}
            </DropdownToggle>
            <DropdownMenu end>
              <NavLink onClick={() => {navigate(traductions(NavbarData).navigate1)}} className="dropdown-item">
                {traductions(NavbarData).fullPackage}
              </NavLink>
              <NavLink onClick={() => {navigate(traductions(NavbarData).navigate2)}} className="dropdown-item">
                {/* División Textil */}
                {traductions(NavbarData).textileDivision}
              </NavLink>
            </DropdownMenu>
          </UncontrolledDropdown>

          <NavItem>
            <NavLink onClick={() => {navigate(traductions(NavbarData).navigate3)}} className="nav-link">
              {traductions(NavbarData).aboutUs}
            </NavLink>
          </NavItem>

          {/* BOTON NOSOTROS DESPLEGABLE  */}
          {/* <UncontrolledDropdown
            inNavbar
            onMouseEnter={showDropdown1}
            onMouseLeave={hideDropdown1}
            isOpen={dropdownOpen1}
            toggle={togglee1}
          >
            <DropdownToggle nav caret className="nav-link">
              Nosotros
            </DropdownToggle>
            <DropdownMenu end>
              <NavLink href="/Nosotros" className="dropdown-item">
                Quiénes Somos
              </NavLink>
              <NavLink href="/Clientes" className="dropdown-item">
                Clientes
              </NavLink>
              <NavLink href="/Showroom" className="dropdown-item">
                Showroom
              </NavLink>
            </DropdownMenu>
          </UncontrolledDropdown> */}
          <NavItem>
            <Button onClick={() => {navigate(traductions(NavbarData).navigate4)}} className="ButtonContactGeneral">
              {traductions(NavbarData).contact}
            </Button>
          </NavItem>
        </Nav>
        <button onClick={handleChange} className="buttonChangeLanguage">
      {language === "es" ? "EN" : "ES"}

      </button>
      </Collapse>
      
    </Navbar>
  );
};

export default MyNavbar;
